@app-bg-text-color: @text-color-secondary;

.ant-modal-header {
    border-radius: 16px 16px 0 0;
}

.ant-modal-content {
    border-radius: 16px;
}

.ant-modal-footer {
    padding: 16px 24px 24px 16px;
}

.ant-message-notice-content {
    border-radius: 16px;
}

.ant-tooltip-inner {
    border-radius: 8px;
}
